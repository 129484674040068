import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import $ from "jquery";
import "datatables.net-dt";
import "datatables.net-buttons/js/dataTables.buttons";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import * as jzip from "jszip";
import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { Helmet } from "react-helmet";
import { Button, FormGroup } from "reactstrap";
import "./styleConsult.css";
import Stack from "@mui/material/Stack";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import Spinner from "../Spinner/spinner";
import { AgGridReact } from "ag-grid-react";
import { localeEs } from "../locale/ag-grid-es";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import Select from "react-select";
import BreadCrum from "../breadCrum/breadCrum";
import withTokenCheck from "../withTokenCheck";

window.JSZip = jzip;

const AppReport = ({ token }) => {
    const [form, setForm] = useState([]);
    const currentDate = dayjs();
    const [dateStart, setDateStart] = useState(currentDate);
    const [dateEnd, setDateEnd] = useState(currentDate);
    const [numberCase, setNumberCase] = useState([]);
    const [selectedCase, setSelectedCase] = useState("");
    const [selectedCase2, setSelectedCase2] = useState("");
    const [loading, setLoading] = useState(true);
    const gridApi = useRef(null);
    const tableRef = useRef(null);
    const etiquetas = ["Lossman Inicio", "Reportes", "Reporte de Facturación"];
    useEffect(() => {
        setLoading(false);
    }, []);

    useEffect(() => {
        if (token) {
            axios
                .get("/edicion-formulario/consulta/reporte", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setNumberCase(response.data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token]);

    const emptyOption = { value: null, label: "Seleccionar o Dejar Vacío" };

    const numberCaseoptions = [
        emptyOption,
        ...numberCase.map((option) => ({
            value: option.id,
            label: option.NumeroCaso ?? option.NumeroCaso,
        })),
    ];

    const handleNumberCaseChange = (selectedOption) => {
        setSelectedCase2(selectedOption.value);
        setSelectedCase(
            numberCaseoptions.find(
                (option) => option.value === selectedOption.value
            )
        );
    };

    const handleConsult = (event) => {
        event.preventDefault();
        const params = {
            FechaInicio: dateStart.format("YYYY-MM-DD"),
            FechaFinal: dateEnd.format("YYYY-MM-DD"),
            FormularioId: selectedCase2,
        };
        if (token) {
            setLoading(true);
            axios
                .get("formulario/facturacion/resumen", {
                    headers: { Authorization: `Bearer ${token}` },
                    params,
                })
                .then((response) => {
                    setForm(response.data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    const defaultColDef = {
        sortable: true,
        resizable: true,
        filter: true,
        editable: true,
    };

    const handleExportCSV = () => {
        gridApi.current.exportDataAsCsv({
            allColumns: true,  // Incluye todas las columnas, incluso las que no están visibles
            onlySelected: false,  // Exporta todas las filas, no solo las seleccionadas
            skipHeader: false,  
        });
        
    };

    const onGridReady = (params) => {
        gridApi.current = params.api;
    };

    const columnDefs = [
        {
            headerName: "Nit Prestador",
            valueGetter: () => "800146932-9",
        },
        { headerName: "Nombre Prestador",  valueGetter: () => "LOSMAN SAS", },
        {
            headerName: "Codigo Servicio",
            valueGetter: (params) => params.data.codigo_servicios?.CodigoServicio || '',
        },
        {
            headerName: "Nombre Servicio",
            field: "descripcion.Texto",
        },
        {
            headerName: "Numero De caso",
            field: "NumeroCaso",
        },
        {
            headerName: "Total dias",
            field: "DiasHospedaje",
        },
        {
            headerName: "Numero Acompañantes",
            field: "acompanantes_count",
        },
        {
            headerName: "Valor Unitario",
            field: "valor_unitario",
        },
        {
            headerName: "Total Facturado",
            field: "total_facturacion",
        },
       
    ];

       const handleInvoiceSubmit = (event) => {
            event.preventDefault();

            const invoices = form.map((item) => item.id);
            axios
                .post(
                    "/facturacion/lossman",
                    {
                        invoices,
                        FechaInicio : dateStart,
                        FechaFinal : dateEnd,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'application/json',  // El encabezado correcto para enviar JSON
                        },
                        responseType: 'blob'  // Para manejar la respuesta binaria (Excel)
                    }
                )
                .then((response) => {
                    const fileName = response.headers['content-disposition']
                    .match(/filename="(.+)"/)[1];
                    const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;
                    link.click();
            
                    Swal.fire({
                        icon: "success",
                        title: "Creado!",
                        text: "El anexo fue generado exitosamente",
                        showCloseButton: true,
                    }).then(() => {
                        handleUpdateTable();
                    });
                })
                .catch((error) => {
                    Swal.fire({
                        icon: "error",
                        title: "Error!",
                        text: "Ha ocurrido un error al generar el anexo.",
                        showCloseButton: true,
                    });
                });
        };

        const handleUpdateTable = () => {
            if (token) {
                setLoading(true);
                const params = {
                    FechaInicio: dateStart.format("YYYY-MM-DD"),
                    FechaFinal: dateEnd.format("YYYY-MM-DD"),
                    FormularioId: selectedCase2,
                };
               
                axios
                    .get("formulario/facturacion/resumen", {
                        headers: { Authorization: `Bearer ${token}` },
                        params,
                    })
                    .then((response) => {
                        setForm(response.data);
                        setLoading(false);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                
            }
        };

    return (
        <>
            <Helmet>
                <title>facturación</title>
            </Helmet>
            <div style={{ position: "relative" }}>
                {loading && <Spinner />}

                <div>
                    <BreadCrum etiquetas={etiquetas} />
                </div>

                {/* <div
                    style={{
                        width: "90%",
                        margin: "0 auto",
                        marginTop: "2rem",
                    }}
                >
                    <label htmlFor="servicioId">
                        Seleccione el numero de caso que desea consultar
                    </label>
                    <FormGroup id="FormEdit">
                        <Select
                            id="SelectEdit"
                            options={numberCaseoptions}
                            value={selectedCase}
                            onChange={handleNumberCaseChange}
                        ></Select>
                    </FormGroup>
                </div> */}
                <div style={{ opacity: loading ? 0.5 : 1 }}>
                    <div className="container mt-5">
                        <label htmlFor="servicioId">
                            Ingrese el rango de fechas en el que desea generar lo información de  facturación
                        </label>
                        <div className="ConsultReport">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Stack spacing={2} sx={{ minWidth: 305 }}>
                                    <DatePicker
                                        label="Fecha Inicial Desde"
                                        id="dateStart"
                                        value={dateStart}
                                        onChange={setDateStart}
                                    />
                                </Stack>
                            </LocalizationProvider>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Stack spacing={2} sx={{ minWidth: 305 }}>
                                    <DatePicker
                                        label="Fecha Inicial Hasta"
                                        value={dateEnd}
                                        onChange={setDateEnd}
                                    />
                                </Stack>
                            </LocalizationProvider>

                            <Button
                                id="buttonConsult"
                                className="mb-3"
                                color="secondary"
                                onClick={handleConsult}
                            >
                                Consultar
                            </Button>
                        </div>
                        <Button color="success" onClick={handleExportCSV}>
                            Detalle Facturación
                        </Button>
                        <span style={{ marginRight: '10px' }} />
                        <Button color="success" onClick={handleInvoiceSubmit}>
                            Descargar Anexo Facturación
                        </Button>
                        <div
                            className="mt-3"
                            style={{ height: "500px", width: "100%" }}
                        >
                            <div
                                className="ag-theme-alpine"
                                style={{ height: "100%", width: "100%" }}
                            >
                                <AgGridReact
                                    rowData={form}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    rowSelection={"multiple"}
                                    pagination={true}
                                    onGridReady={onGridReady}
                                    localeText={localeEs}
                                    paginationPageSize={20}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default withTokenCheck(AppReport);
